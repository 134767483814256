/**
 * @fuegokit/tokens 0.21.1
 * Do not edit directly
 * Generated on Tue, 17 Sep 2024 17:55:27 GMT
 */

export default {
  radii: {
    "0": "0px",
    "1": "2px",
    "2": "4px",
    "3": "6px",
    "4": "8px",
    "5": "99999px",
  },
};
