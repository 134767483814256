import { COMMON, TYPOGRAPHY, get } from '../../constants.js';
import sx from '../../sx.js';
import styled from 'styled-components';

// Mapping objects for styling decisions
const fontSizes = {
  h100: '11px',
  h200: '12px',
  h300: '12px',
  h400: '14px',
  h500: '16px',
  h600: '20px',
  h700: '24px',
  h800: '29px',
  h900: '35px'
};
const fontWeights = {
  h100: 700,
  h200: 600,
  h300: 600,
  h400: 600,
  h500: 600,
  h600: 500,
  h700: 500,
  h800: 600,
  h900: 500
};
const letterSpacings = {
  h100: 'normal',
  h200: 'normal',
  h300: 'normal',
  h400: '-0.003em',
  h500: '-0.003em',
  h600: '-0.008em',
  h700: '-0.01em',
  h800: '-0.01em',
  h900: '-0.01em'
};
const lineHeights = {
  h100: '1.45',
  h200: '1.45',
  h300: '1.45',
  h400: '1.45',
  h500: '1.25',
  h600: '1.2',
  h700: '1.17',
  h800: '1.1',
  h900: '1.14'
};
const textTransforms = {
  h100: 'none',
  h200: 'none',
  h300: 'uppercase',
  h400: 'none',
  h500: 'none',
  h600: 'none',
  h700: 'none',
  h800: 'none',
  h900: 'none'
};
const colors = {
  h100: 'colors.text.subtle',
  h200: 'colors.text.subtle',
  h300: 'colors.text.default',
  h400: 'colors.text.default',
  h500: 'colors.text.default',
  h600: 'colors.text.default',
  h700: 'colors.text.default',
  h800: 'colors.text.default',
  h900: 'colors.text.default'
};
const HeadingBase = styled.h2.withConfig({
  displayName: "Heading__HeadingBase",
  componentId: "sc-e874gn-0"
})(["", " ", " font-size:", ";font-weight:", ";letter-spacing:", ";line-height:", ";text-transform:", ";color:", ";", ";"], COMMON, TYPOGRAPHY, ({
  variant
}) => fontSizes[variant || 'h800'], ({
  variant
}) => fontWeights[variant || 'h800'], ({
  variant
}) => letterSpacings[variant || 'h800'], ({
  variant
}) => lineHeights[variant || 'h800'], ({
  variant
}) => textTransforms[variant || 'h800'] || 'none', ({
  variant
}) => get(colors[variant || 'h800']), sx);
const Heading = styled(HeadingBase).withConfig({
  displayName: "Heading",
  componentId: "sc-e874gn-1"
})(["", ";"], sx);
var Heading$1 = Heading;

export { Heading$1 as default };
