import { Text } from "@fuegokit/react";
import React from "react";

export const CaptionTitle = ({ children }) => {
  return (
    <Text
      as="p"
      sx={{
        mt: 2,
        fontSize: 1,
        fontWeight: "bold",
        color: "text.default",
      }}
    >
      {children}
    </Text>
  );
};
