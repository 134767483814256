import isEmpty from 'lodash.isempty';
import isObject from 'lodash.isobject';
import chroma from 'chroma-js';

/* eslint-disable @typescript-eslint/no-explicit-any */
function isColorValue(value) {
  return chroma.valid(value);
}
function isShadowValue(value) {
  return typeof value === 'string' && /(inset\s|)([0-9.]+(\w*)\s){1,4}(rgb[a]?\(.*\)|\w+)/.test(value);
}
const filterObject = (obj, predicate) => {
  if (Array.isArray(obj)) {
    return obj.filter(predicate);
  }
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (isObject(value)) {
      const result = filterObject(value, predicate);

      // Don't include empty objects or arrays
      if (!isEmpty(result)) {
        // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        acc[key] = result;
      }
    } else if (predicate(value)) {
      // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      acc[key] = value;
    }
    return acc;
  }, {});
};
const partitionColors = colors => {
  return {
    colors: filterObject(colors, value => isColorValue(value)),
    shadows: filterObject(colors, value => isShadowValue(value))
  };
};
const omitScale = obj => {
  const {
    scale,
    ...rest
  } = obj;
  return rest;
};

export { filterObject, isColorValue, isShadowValue, omitScale, partitionColors };
