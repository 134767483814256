import { MDXProvider } from "@mdx-js/react"
import { ThemeProvider } from "@fuegokit/react"
import { BrandThemeProvider } from "@fuegokit/react/experimental"
import * as fuegokitReactComponents from "@fuegokit/react"
import mdxComponents from "./mdx-components"

import React from "react"

const components = {
  ...fuegokitReactComponents,
  ...mdxComponents,
}
// @TODO - types
function wrapRootElement({ element }: any) {
  return (
    <MDXProvider components={components}>
      <BrandThemeProvider>{element}</BrandThemeProvider>
    </MDXProvider>
  )
}

export default wrapRootElement
