import React from "react";
import styled from "styled-components";
import {
  Box,
  Text,
  Stack,
  Heading,
  Link,
  themeGet,
  Spacer,
} from "@fuegokit/react";
import {
  AkCheckCircleIcon,
  AkCheckIcon,
  AkCrossCircleIcon,
  AkWarningIcon,
} from "@fuegokit/fuegoicons-react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";

export const DoDontChecklist = ({ children }) => {
  return (
    <Stack size={3}>
      <Spacer size={4} axis={`vertical`} />
      <List>{children}</List>
    </Stack>
  );
};

export const DoDontChecklistHeading = ({ children }) => {
  return <Heading variant="h600">{children}</Heading>;
};

export const DoDontChecklistLink = ({ href, children }) => {
  return (
    <StyledLink href={href} target="_blank">
      {children}
    </StyledLink>
  );
};

export const DoDontChecklistItem = ({
  appearance,
  docsTheme = "brand",
  children,
}) => {
  const getColor = () => {
    switch (appearance) {
      case "success":
        return docsTheme === "brand" ? "icon.success.default" : "icon.success";
      case "danger":
        return docsTheme === "brand" ? "icon.danger.default" : "icon.danger";
      case "warning":
        return "icon.warning.default";
      default:
        return "icon.default";
    }
  };

  const getIcon = () => {
    switch (appearance) {
      case "success":
        return (
          <SuccessIcon docsTheme={docsTheme}>
            <AkCheckCircleIcon size={16} />
          </SuccessIcon>
        );
      case "danger":
        return (
          <DangerIcon docsTheme={docsTheme}>
            <AkCrossCircleIcon size={16} />
          </DangerIcon>
        );
      case "warning":
        return (
          <WarningIcon docsTheme={docsTheme}>
            <AkWarningIcon size={16} />
          </WarningIcon>
        );
      default:
        return (
          <NeutralIcon docsTheme={docsTheme}>
            <AkCheckIcon size={16} />
          </NeutralIcon>
        );
    }
  };

  return (
    <StyledListItem sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Box sx={{ mr: 2 }}>{getIcon()}</Box>
      <Text>{children}</Text>
    </StyledListItem>
  );
};

const List = styled.ul`
  padding: 0;
  font-size: ${themeGet("fontSizes.4")};
  list-style: none;
`;

const StyledListItem = styled.li`
  margin: ${themeGet("space.3")} 0;
  line-height: ${themeGet("lineHeights.relaxed")};

  display: flex;
  flex-direction: row;
  gap: ${themeGet("space.2")};

  ${InlineCode} {
    display: contents;
  }
  ${Text} {
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
      segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto,
      arial, sans-serif !important;
  }
`;
const StyledLink = styled(Link)`
  font-size: ${themeGet("fontSizes.4")};
  font-weight: ${themeGet("fontWeights.normal")};
`;
const SuccessIcon = styled(Box)`
  color: ${(p) =>
    p.docsTheme === "brand"
      ? themeGet("colors.icon.success.default")
      : themeGet("colors.icon.success")};
`;

const DangerIcon = styled(Box)`
  color: ${(p) =>
    p.docsTheme === "brand"
      ? themeGet("colors.icon.danger.default")
      : themeGet("colors.icon.danger")};
  position: relative;
`;
const WarningIcon = styled(Box)`
  color: ${themeGet("colors.icon.warning.default")};
  position: relative;
`;
const NeutralIcon = styled(Box)`
  color: ${themeGet("colors.icon.default")};
  position: relative;
`;
