import sx from '../../sx.js';
import { themeGet } from '../../utils/theme-get.js';
import styled from 'styled-components';

const StyledButton = styled.button.withConfig({
  displayName: "styles__StyledButton",
  componentId: "sc-ek70jg-0"
})(["align-items:baseline;border-width:0;box-sizing:border-box;display:inline-flex;font-size:inherit;font-style:normal;font-weight:500;max-width:100%;position:relative;text-align:center;text-decoration:none;align-items:center;transition:background-color 0.1s ease-out,box-shadow 0.15s cubic-bezier(0.47,0.03,0.49,1.38);white-space:nowrap;user-select:none;line-height:", ";padding:", " ", ";border-radius:", ";vertical-align:middle;justify-content:center;", ";"], themeGet('lineHeights.default'), themeGet('space.1'), themeGet('space.3'), themeGet('radii.1'), sx);

export { StyledButton };
