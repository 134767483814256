import rawLightColorsTokens from '@fuegokit/tokens/tokens-next-gen-private/js/esm/fk-atlassian/colors/light.mjs';
import rawDarkColorsTokens from '@fuegokit/tokens/tokens-next-gen-private/js/esm/fk-atlassian/colors/dark.mjs';
import sizeTokens from '@fuegokit/tokens/tokens-next-gen-private/js/esm/fk-atlassian/size/size.mjs';
import rawTypographyTokens from '@fuegokit/tokens/tokens-next-gen-private/js/esm/fk-atlassian/typography/typography.mjs';
import radiiTokens from '@fuegokit/tokens/tokens-next-gen-private/js/esm/fk-atlassian/radii/radii.mjs';
import { partitionColors, omitScale } from './index.js';

/* eslint-disable import/extensions */

// Define additional properties
const breakpoints = ['350px', '544px', '768px', '1012px', '1280px'];
const animation = {
  easeOutCubic: 'cubic-bezier(0.33, 1, 0.68, 1)'
};
const borderWidths = [0, '1px'];

// Extract size and radii token data
const sizeObject = sizeTokens.size;
const spaceObject = sizeTokens.space;
const radiiObject = radiiTokens.radii;

// Combine color tokens for light and dark schemes
const colors = {
  light: rawLightColorsTokens,
  dark: rawDarkColorsTokens
};

// Directly extract shadows from the tokens and nest under `elevation.shadow`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractShadows = tokens => {
  var _tokens$elevation;
  return {
    elevation: {
      shadow: ((_tokens$elevation = tokens.elevation) === null || _tokens$elevation === void 0 ? void 0 : _tokens$elevation.shadow) || {}
    }
  };
};
// Process colors into color schemes using partitionColors and omitScale
const colorSchemes = Object.entries(colors).reduce((acc, [name, variables]) => {
  const shadows = extractShadows(variables);
  const colorsWithoutScale = omitScale(variables);
  partitionColors(variables);
  acc[name] = {
    colors: colorsWithoutScale,
    shadows
  };
  return acc;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}, {});

// Extract typography tokens
const {
  fontFamily,
  fontFamilies,
  fontStack,
  fontSize: fontSizeObject,
  fontSizes: fontSizesObject,
  lineHeight,
  lineHeights,
  fontWeight,
  fontWeights,
  font,
  letterSpacing
} = rawTypographyTokens;

// Convert extracted tokens into arrays
const radii = Object.values(radiiObject);
const fontSize = Object.values(fontSizeObject);
const fontSizes = Object.values(fontSizesObject);
const space = Object.values(spaceObject);

// Legacy font tokens (if needed)
const legacyFontsTokens = fontFamily;

// Create the theme object
const defaultTheme = {
  // extensions
  animation,
  borderWidths,
  breakpoints,
  // tokens
  colorSchemes,
  radii,
  size: sizeObject,
  space,
  fontSize,
  lineHeight,
  fontWeight,
  fontStack,
  fontFamily,
  font,
  letterSpacing,
  // fallbacks
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  fonts: legacyFontsTokens
};

export { defaultTheme };
