import rawColorsTokens from '@fuegokit/tokens/tokens-next-gen-private/js/esm/brand/colors';
import rawTypographyTokens from '@fuegokit/tokens/tokens-next-gen-private/js/esm/brand/typography';
import radiiTokens from '@fuegokit/tokens/tokens-next-gen-private/js/esm/brand/radii';
import sizeTokens from '@fuegokit/tokens/tokens-next-gen-private/js/esm/brand/size';
import { partitionColors, omitScale } from '../../utils/theme-utils/index.js';

const colorSchemes = Object.entries(rawColorsTokens).reduce((acc, [name, variables]) => {
  const {
    colors: filteredColors,
    shadows: filteredShadows
  } = partitionColors(variables);
  // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
  acc[name] = {
    colors: omitScale(filteredColors),
    shadows: omitScale(filteredShadows)
  };
  return acc;
}, {});
const {
  size: sizeTokensObject
} = sizeTokens;
const {
  typography: typographyTokens
} = rawTypographyTokens;
const {
  fontStack,
  fontFamily,
  fontSizes: fontSizeObject,
  lineHeights: lineHeightsObject,
  fontWeight,
  font
} = typographyTokens;
const {
  radii: radiiObject
} = radiiTokens;
const space = Object.values(sizeTokensObject.space);
const size = Object.values(sizeTokensObject.size);
const radii = Object.values(radiiObject);
const fontSize = Object.values(fontSizeObject);
const lineHeight = Object.values(lineHeightsObject);
const theme = {
  /** colors, spacing, typoography */
  lineHeight,
  fontWeight,
  size,
  space,
  radii,
  colorSchemes,
  fontSize,
  fontFamily,
  fontStack,
  font
};

export { theme };
