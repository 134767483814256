import { Text } from "@fuegokit/react";
import React from "react";

function ImageCaption(props) {
  return (
    <Text
      as="p"
      {...props}
      sx={{
        mt: 2,
        mb: 3,
        fontSize: 4,
        color: "text.subtlest",
      }}
    />
  );
}

export default ImageCaption;
