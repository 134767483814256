/**
 * @fuegokit/tokens 0.21.1
 * Do not edit directly
 * Generated on Tue, 17 Sep 2024 17:55:28 GMT
 */

export default {
  scale: {
    gray: {
      "100": "#EBECF0",
      "200": "#DFE1E6",
      "300": "#C1C7D0",
      "400": "#8993A4",
      "500": "#6B778C",
      "600": "#57606a",
      "700": "#424a53",
      "800": "#32383f",
      "900": "#24292f",
      "000": "#FAFBFC",
    },
    neutral: {
      "100": "#F7F8F9",
      "200": "#F1F2F4",
      "300": "#DCDFE4",
      "400": "#B3B9C4",
      "500": "#8590A2",
      "600": "#758195",
      "700": "#626F86",
      "800": "#44546F",
      "900": "#2C3E5D",
      "1000": "#172B4D",
      "1100": "#091E42",
      "000": "#FFFFFF",
    },
    neutralAlpha: {
      "100": "#091e4208",
      "200": "#091e420f",
      "300": "#091e4224",
      "400": "#091e4224",
      "500": "#091e427d",
      "600": "#091e428f",
      "700": "#091e429e",
      "800": "#091e42ba",
      "900": "#091e42d6",
      "000": "#ffffff00",
    },
    blue: {
      "100": "#CCE0FF",
      "200": "#85B8FF",
      "300": "#579DFF",
      "400": "#388BFF",
      "500": "#1D7AFC",
      "600": "#0C66E4",
      "700": "#0055CC",
      "800": "#09326C",
      "900": "#1C2B41",
      "000": "#E9F2FF",
    },
    teal: {
      "100": "#C6EDFB",
      "200": "#9DD9EE",
      "300": "#6CC3E0",
      "400": "#42B2D7",
      "500": "#2898BD",
      "600": "#227D9B",
      "700": "#206A83",
      "800": "#164555",
      "900": "#1E3137",
      "000": "#E7F9FF",
    },
    purple: {
      "100": "#DFD8FD",
      "200": "#B8ACF6",
      "300": "#9F8FEF",
      "400": "#8F7EE7",
      "500": "#8270DB",
      "600": "#6E5DC6",
      "700": "#5E4DB2",
      "800": "#352C63",
      "900": "#2B273F",
      "000": "#F3F0FF",
    },
    green: {
      "100": "#BAF3DB",
      "200": "#7EE2B8",
      "300": "#4BCE97",
      "400": "#2ABB7F",
      "500": "#22A06B",
      "600": "#1F845A",
      "700": "#216E4E",
      "800": "#164B35",
      "900": "#1C3329",
      "000": "#DCFFF1",
    },
    yellow: {
      "100": "#F8E6A0",
      "200": "#F5CD47",
      "300": "#E2B203",
      "400": "#CF9F02",
      "500": "#B38600",
      "600": "#946F00",
      "700": "#7F5F01",
      "800": "#533F04",
      "900": "#332E1B",
      "000": "#FFF7D6",
    },
    red: {
      "100": "#ffd5d2",
      "200": "#fd9891",
      "300": "#f87168",
      "400": "#f15b50",
      "500": "#e2483d",
      "600": "#c9372c",
      "700": "#ae2e24",
      "800": "#5d1f1a",
      "900": "#42221f",
      "000": "#ffeceb",
    },
    orange: {
      "100": "#fedec8",
      "200": "#fec195",
      "300": "#fea362",
      "400": "#f38a3f",
      "500": "#e56910",
      "600": "#c25100",
      "700": "#a54800",
      "800": "#702e00",
      "900": "#38291e",
      "000": "#fff3eb",
    },
    magenta: {
      "100": "#FDD0EC",
      "200": "#F797D2",
      "300": "#E774BB",
      "400": "#DA62AC",
      "500": "#CD519D",
      "600": "#AE4787",
      "700": "#943D73",
      "800": "#50253F",
      "900": "#3D2232",
      "000": "#FFECF8",
    },
    lime: {
      "100": "#D3F1A7",
      "200": "#B3DF72",
      "300": "#94C748",
      "400": "#82B536",
      "500": "#6A9A23",
      "600": "#5B7F24",
      "700": "#4C6B1F",
      "800": "#37471F",
      "900": "#28311B",
      "000": "#EFFFD6",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#FFFFFF",
        hovered: "#F1F2F4",
        pressed: "#DCDFE4",
      },
      sunken: "#F7F8F9",
      raised: {
        default: "#FFFFFF",
        hovered: "#F7F8F9",
        pressed: "#F1F2F4",
      },
      overlay: {
        default: "#FFFFFF",
        hovered: "#F1F2F4",
        pressed: "#DCDFE4",
      },
    },
    shadow: {
      raised: "0px 1px 1px 0px #091e4240, 0px 0px 1px 0px #091e424f",
      overflow: {
        default: "0px 0px 12px 0px #0304048f, 0px 0px 1px 0px #03040480",
        spread: "#091e4229",
        perimeter: "#091e421f",
      },
      overlay: "0px 8px 12px 0px #091e4226, 0px 0px 1px 0px #091e424f",
    },
  },
  border: {
    default: "#091e4224",
    bold: "#758195",
    selected: "#1D7AFC",
    focused: "#388BFF",
    subtle: "#091e420f",
    input: "#091e4224",
    inverse: "#FFFFFF",
    disabled: "#091e420f",
    brand: "#0C66E4",
    danger: "#e2483d",
    warning: "#e56910",
    success: "#22A06B",
    discovery: "#8270DB",
    information: "#1D7AFC",
    accent: {
      blue: "#1D7AFC",
      red: "#e2483d",
      orange: "#e56910",
      yellow: "#B38600",
      green: "#22A06B",
      magenta: "#CD519D",
      purple: "#8270DB",
      teal: "#2898BD",
      gray: "#8590A2",
    },
    table: {
      default: "#091e4224",
      container: "#ffffff00",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#E9F2FF",
        subtler: "#CCE0FF",
        subtle: "#579DFF",
        bolder: "#0C66E4",
      },
      red: {
        subtlest: "#ffeceb",
        subtler: "#ffd5d2",
        subtle: "#f87168",
        bolder: "#c9372c",
      },
      orange: {
        subtlest: "#fff3eb",
        subtler: "#fedec8",
        subtle: "#fea362",
        bolder: "#c25100",
      },
      yellow: {
        subtlest: "#FFF7D6",
        subtler: "#F8E6A0",
        subtle: "#F5CD47",
        bolder: "#946F00",
      },
      green: {
        subtlest: "#DCFFF1",
        subtler: "#BAF3DB",
        subtle: "#4BCE97",
        bolder: "#1F845A",
      },
      purple: {
        subtlest: "#F3F0FF",
        subtler: "#DFD8FD",
        subtle: "#9F8FEF",
        bolder: "#6E5DC6",
      },
      teal: {
        subtlest: "#E7F9FF",
        subtler: "#C6EDFB",
        subtle: "#6CC3E0",
        bolder: "#227D9B",
      },
      magenta: {
        subtlest: "#FFECF8",
        subtler: "#FDD0EC",
        subtle: "#E774BB",
        bolder: "#AE4787",
      },
      lime: {
        subtlest: "#EFFFD6",
        subtler: "#D3F1A7",
        subtle: "#94C748",
        bolder: "#5B7F24",
      },
      gray: {
        subtlest: "#F1F2F4",
        subtler: "#DCDFE4",
        subtle: "#8590A2",
        bolder: "#626F86",
      },
    },
    input: {
      default: "#FFFFFF",
      hovered: "#F7F8F9",
      pressed: "#FFFFFF",
    },
    inverse: {
      subtle: {
        default: "#00000029",
        hovered: "#0000003d",
        pressed: "#00000052",
      },
    },
    neutral: {
      default: {
        "[default]": "#091e420f",
        hovered: "#091e4224",
        pressed: "#091e4224",
      },
      subtle: {
        default: "#00000000",
        hovered: "#091e420f",
        pressed: "#091e4224",
      },
      bold: {
        default: "#44546F",
        hovered: "#2C3E5D",
        pressed: "#172B4D",
      },
    },
    brand: {
      subtlest: {
        default: "#E9F2FF",
        hovered: "#CCE0FF",
        pressed: "#85B8FF",
      },
      bold: {
        default: "#0C66E4",
        hovered: "#0055CC",
        pressed: "#09326C",
      },
      boldest: {
        default: "#1C2B41",
        hovered: "#09326C",
        pressed: "#0055CC",
      },
    },
    selected: {
      default: {
        "[default]": "#E9F2FF",
        hovered: "#CCE0FF",
        pressed: "#85B8FF",
      },
      bold: {
        default: "#0C66E4",
        hovered: "#0055CC",
        pressed: "#09326C",
      },
    },
    disabled: "#F7F8F9",
    information: {
      default: {
        "[default]": "#E9F2FF",
        hovered: "#CCE0FF",
        pressed: "#85B8FF",
      },
      bold: {
        default: "#0C66E4",
        hovered: "#0055CC",
        pressed: "#09326C",
      },
    },
    danger: {
      default: {
        "[default]": "#ffeceb",
        hovered: "#ffd5d2",
        pressed: "#fd9891",
      },
      bold: {
        default: "#c9372c",
        hovered: "#ae2e24",
        pressed: "#5d1f1a",
      },
    },
    success: {
      default: {
        "[default]": "#DCFFF1",
        hovered: "#BAF3DB",
        pressed: "#7EE2B8",
      },
      bold: {
        default: "#1F845A",
        hovered: "#216E4E",
        pressed: "#164B35",
      },
    },
    discovery: {
      default: {
        "[default]": "#F3F0FF",
        hovered: "#DFD8FD",
        pressed: "#B8ACF6",
      },
      bold: {
        default: "#6E5DC6",
        hovered: "#5E4DB2",
        pressed: "#352C63",
      },
    },
    warning: {
      default: {
        "[default]": "#FFF7D6",
        hovered: "#F8E6A0",
        pressed: "#F8E6A0",
      },
      bold: {
        default: "#F5CD47",
        hovered: "#E2B203",
        pressed: "#CF9F02",
      },
    },
  },
  blanket: {
    default: "#091e427d",
    selected: "#388bff14",
    danger: "#ef5c4814",
  },
  text: {
    default: "#172B4D",
    subtle: "#44546F",
    subtlest: "#626F86",
    disabled: "#8590A2",
    selected: "#0C66E4",
    inverse: "#FFFFFF",
    success: "#216E4E",
    danger: "#ae2e24",
    information: "#0055CC",
    warning: {
      default: "#a54800",
      inverse: "#172B4D",
    },
    discovery: "#5E4DB2",
    brand: "#0C66E4",
    accent: {
      blue: {
        default: "#0055CC",
        bolder: "#09326C",
      },
      red: {
        default: "#ae2e24",
        bolder: "#5d1f1a",
      },
      orange: {
        default: "#a54800",
        bolder: "#702e00",
      },
      yellow: {
        default: "#7F5F01",
        bolder: "#533F04",
      },
      green: {
        default: "#216E4E",
        bolder: "#164B35",
      },
      purple: {
        default: "#5E4DB2",
        bolder: "#352C63",
      },
      teal: {
        default: "#206A83",
        bolder: "#164555",
      },
      magenta: {
        default: "#943D73",
        bolder: "#50253F",
      },
      gray: {
        default: "#44546F",
        bolder: "#091E42",
      },
      lime: {
        default: "#4C6B1F",
        bolder: "#37471F",
      },
    },
  },
  link: {
    default: "#0C66E4",
    hovered: "#0C66E4",
    pressed: "#0055CC",
    visited: "#5E4DB2",
  },
  icon: {
    accent: {
      blue: "#1D7AFC",
      red: "#c9372c",
      orange: "#e56910",
      yellow: "#B38600",
      green: "#22A06B",
      purple: "#8270DB",
      teal: "#2898BD",
      magenta: "#CD519D",
      gray: "#758195",
      lime: "#6A9A23",
    },
    default: "#44546F",
    subtle: "#626F86",
    inverse: "#FFFFFF",
    disabled: "#8590A2",
    brand: "#0C66E4",
    selected: "#0C66E4",
    danger: "#c9372c",
    success: "#22A06B",
    discovery: "#8270DB",
    information: "#1D7AFC",
    warning: {
      default: "#e56910",
      inverse: "#172B4D",
    },
  },
  interaction: {
    hovered: "#00000029",
    pressed: "#00000052",
  },
  skeleton: {
    default: "#091e420f",
    subtle: "#091e4208",
  },
  opacity: {
    disabled: 0.2,
    loading: 0.4,
  },
  chart: {
    brand: {
      default: "#1D7AFC",
      hovered: "#0C66E4",
    },
    neutral: {
      default: "#8590A2",
      hovered: "#758195",
    },
    success: {
      default: {
        "[default]": "#22A06B",
        hovered: "#1F845A",
      },
      bold: {
        default: "#216E4E",
        hovered: "#164B35",
      },
    },
    danger: {
      default: {
        "[default]": "#f15b50",
        hovered: "#e2483d",
      },
      bold: {
        default: "#ae2e24",
        hovered: "#5d1f1a",
      },
    },
    warning: {
      default: {
        "[default]": "#B38600",
        hovered: "#946F00",
      },
      bold: {
        default: "#7F5F01",
        hovered: "#533F04",
      },
    },
    information: {
      default: {
        "[default]": "#1D7AFC",
        hovered: "#0C66E4",
      },
      bold: {
        default: "#0055CC",
        hovered: "#09326C",
      },
    },
    discovery: {
      default: {
        "[default]": "#8F7EE7",
        hovered: "#8270DB",
      },
      bold: {
        default: "#5E4DB2",
        hovered: "#352C63",
      },
    },
    categorical: {
      "1": {
        default: "#2898BD",
        hovered: "#227D9B",
      },
      "2": {
        default: "#5E4DB2",
        hovered: "#352C63",
      },
      "3": {
        default: "#e56910",
        hovered: "#c25100",
      },
      "4": {
        default: "#943D73",
        hovered: "#50253F",
      },
      "5": {
        default: "#09326C",
        hovered: "#1C2B41",
      },
      "6": {
        default: "#8F7EE7",
        hovered: "#8270DB",
      },
      "7": {
        default: "#50253F",
        hovered: "#3D2232",
      },
      "8": {
        default: "#a54800",
        hovered: "#702e00",
      },
    },
    blue: {
      bold: {
        default: "#388BFF",
        hovered: "#388BFF",
      },
      bolder: {
        default: "#1D7AFC",
        hovered: "#0C66E4",
      },
      boldest: {
        default: "#0055CC",
        hovered: "#09326C",
      },
    },
    red: {
      bold: {
        default: "#f15b50",
        hovered: "#e2483d",
      },
      bolder: {
        default: "#e2483d",
        hovered: "#c9372c",
      },
      boldest: {
        default: "#ae2e24",
        hovered: "#5d1f1a",
      },
    },
    orange: {
      bold: {
        default: "#e56910",
        hovered: "#c25100",
      },
      bolder: {
        default: "#c25100",
        hovered: "#a54800",
      },
      boldest: {
        default: "#a54800",
        hovered: "#702e00",
      },
    },
    yellow: {
      bold: {
        default: "#B38600",
        hovered: "#946F00",
      },
      bolder: {
        default: "#946F00",
        hovered: "#7F5F01",
      },
      boldest: {
        default: "#7F5F01",
        hovered: "#533F04",
      },
    },
    green: {
      bold: {
        default: "#22A06B",
        hovered: "#1F845A",
      },
      bolder: {
        default: "#1F845A",
        hovered: "#216E4E",
      },
      boldest: {
        default: "#216E4E",
        hovered: "#164B35",
      },
    },
    teal: {
      bold: {
        default: "#2898BD",
        hovered: "#227D9B",
      },
      bolder: {
        default: "#227D9B",
        hovered: "#206A83",
      },
      boldest: {
        default: "#206A83",
        hovered: "#164555",
      },
    },
    purple: {
      bold: {
        default: "#8F7EE7",
        hovered: "#8270DB",
      },
      bolder: {
        default: "#8270DB",
        hovered: "#6E5DC6",
      },
      boldest: {
        default: "#5E4DB2",
        hovered: "#352C63",
      },
    },
    magenta: {
      bold: {
        default: "#DA62AC",
        hovered: "#CD519D",
      },
      bolder: {
        default: "#CD519D",
        hovered: "#AE4787",
      },
      boldest: {
        default: "#943D73",
        hovered: "#50253F",
      },
    },
    gray: {
      bold: {
        default: "#8590A2",
        hovered: "#758195",
      },
      bolder: {
        default: "#758195",
        hovered: "#626F86",
      },
      boldest: {
        default: "#44546F",
        hovered: "#2C3E5D",
      },
    },
    lime: {
      bold: {
        default: "#6A9A23",
        hovered: "#5B7F24",
      },
      bolder: {
        default: "#5B7F24",
        hovered: "#4C6B1F",
      },
      boldest: {
        default: "#4C6B1F",
        hovered: "#37471F",
      },
    },
  },
};
