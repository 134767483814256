import styled from "styled-components"
import { Switch, themeGet } from "@fuegokit/react"
import { SwitchProps, SwitchLabelProps, SwitchThumbProps } from "./types"

export const StyledSwitch = styled(Switch)<SwitchProps>`
  all: unset;
  //
  width: 3rem;
  height: 1.5rem;
  background-color: ${p =>
    p.activeTheme.name === "appfire"
      ? themeGet("colors.elevation.surface.default.[default]")
      : themeGet("colors.border.input")};
  border-radius: ${themeGet("radii.5")};
  border: 1px solid;
  border-color: ${p =>
    p.activeTheme.name === "appfire"
      ? themeGet("colors.border.input")
      : "transparent"};
  position: relative;

  -webkit-tap-highlight-color: ${themeGet("colors.border.input")};
  &:focus {
    box-shadow: 0 0 0 2px ${themeGet("colors.border.focused")};
  }
  &[data-state="checked"] {
    background-color: ${p =>
      p.activeTheme.name === "appfire"
        ? themeGet("colors.text.success")
        : themeGet("colors.background.success.bold.default")};
  }
`

export const StyledSwitchLabel = styled.label<SwitchLabelProps>`
  color: ${themeGet("colors.text.default")};
  font-size: ${themeGet("fontSizes.3")};
  line-height: ${themeGet("lineHeights.condensed")};
  padding-right: ${themeGet("space.2")};
`

export const StyledSwitchThumb = styled(Switch.Thumb)<SwitchThumbProps>`
  display: block;
  width: 18px;
  height: 18px;
  transform: translateX(3px);
  background-color: ${p =>
    p.activeTheme?.name === "appfire"
      ? themeGet("colors.icon.default")
      : themeGet("colors.icon.inverse")};
  border-radius: 9999px;
  /* box-shadow: 0 2px 2px ${themeGet(
    "colors.background.accent.gray.subtle"
  )}; */
  transition: transform 100ms;
  will-change: transform;
  &[data-state="checked"] {
    transform: translateX(27px);
  }
`
