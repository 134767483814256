import * as styledSystem from 'styled-system';
import * as themeGet from './utils/theme-get.js';
import { themeGet as themeGet$1 } from './utils/theme-get.js';
import defaultTheme from './theme.js';

// we defined our own getter function here, and set it to getKey
const {
  get: getKey
} = themeGet;

// we use the compose and system functions from styled-system
const {
  compose,
  system
} = styledSystem;

// this is the existential getter function we use to look up type-safe dot-delimited theme objects
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const get = key => themeGet$1(key, getKey(defaultTheme, key));
// export const get = (key: FuegokitThemeTypes) => themeGet(key)

// Common props from styled-system and their types
const COMMON = compose(styledSystem.space, styledSystem.color, styledSystem.display);
// Typography props from styled-system and types
// white-space is commonly used to make text overflow into an ellipsis, but it doesn't
// come with styled-system, so we're creating a custom css prop here.
const whiteSpace = system({
  whiteSpace: {
    property: 'whiteSpace'
  }
});
const TYPOGRAPHY = compose(styledSystem.typography, whiteSpace);

// Atlassian width name types

// line-height utility
const getLineHeight = (sizePx, lineHeightPx) => lineHeightPx / sizePx;

// width sizes for inline dialogs
const INLINE_DIALOG_SIZES = {
  small: 256,
  medium: 340
};
// default page sizes
const PAGE_SIZES = {
  small: 600,
  medium: 910,
  large: 1200,
  'x-large': '100%'
};
const BREAKPOINTS = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px'
};
const OLD_TYPOGRAPHY = {
  fontFamily: {
    normal: {
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`
    },
    code: {
      fontFamily: `'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace`
    }
  }
};

export { BREAKPOINTS, COMMON, INLINE_DIALOG_SIZES, OLD_TYPOGRAPHY, PAGE_SIZES, TYPOGRAPHY, get, getLineHeight };
