exports.components = {
  "component---content-accessibility-overview-mdx": () => import("./../../../content/accessibility/overview.mdx" /* webpackChunkName: "component---content-accessibility-overview-mdx" */),
  "component---content-content-error-messages-mdx": () => import("./../../../content/content/error-messages.mdx" /* webpackChunkName: "component---content-content-error-messages-mdx" */),
  "component---content-content-foundations-mdx": () => import("./../../../content/content/foundations.mdx" /* webpackChunkName: "component---content-content-foundations-mdx" */),
  "component---content-content-grammar-and-mechanics-mdx": () => import("./../../../content/content/grammar-and-mechanics.mdx" /* webpackChunkName: "component---content-content-grammar-and-mechanics-mdx" */),
  "component---content-content-index-mdx": () => import("./../../../content/content/index.mdx" /* webpackChunkName: "component---content-content-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-design-atlassian-tsx": () => import("./../../../src/pages/design-atlassian.tsx" /* webpackChunkName: "component---src-pages-design-atlassian-tsx" */),
  "component---src-pages-design-monday-tsx": () => import("./../../../src/pages/design-monday.tsx" /* webpackChunkName: "component---src-pages-design-monday-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */)
}

