import styled from 'styled-components';
import { COMMON, TYPOGRAPHY } from '../../constants.js';
import sx from '../../sx.js';

/**
 * The Text component is a utility component that applies typography styles to the text inside.
 *
 * When choosing whether to use margin, consider using a structural layout component like `<Spacer>` instead.
 */
const Text = styled.span.withConfig({
  displayName: "Text",
  componentId: "sc-5bxhui-0"
})(["", " ", " ", ";"], COMMON, TYPOGRAPHY, sx);
var Text$1 = Text;

export { Text$1 as default };
