import styled from 'styled-components';
import { space, typography, layout, color, flexbox, grid, background, border, position, shadow } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';
import sx from '../../sx.js';

/**
 * The Box component is a low-level, composable, abstract theme-aware utility component. It applies CSS properties directly via its props.
 *
 */
const Box = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => shouldForwardProp(prop) && defaultValidatorFn(prop)
}).withConfig({
  displayName: "Box",
  componentId: "sc-n2zjnd-0"
})(["", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", ""], space, typography, layout, color, flexbox, grid, background, border, position, shadow, sx);
var Box$1 = Box;

export { Box$1 as default };
